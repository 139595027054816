import g1 from './../../assets/images/g1.jpg';
import g2 from './../../assets/images/g2.jpg';
import g3 from './../../assets/images/g3.jpg';
import g4 from './../../assets/images/g4.jpg';
import g5 from './../../assets/images/g5.jpg';
import g6 from './../../assets/images/g6.jpg';
import g8 from './../../assets/images/g8.jpg';
import g9 from './../../assets/images/g9.jpg';
import g13 from './../../assets/images/g13.jpg';
import g14 from './../../assets/images/g14.jpg';
import g15 from './../../assets/images/g15.jpg';
import g16 from './../../assets/images/g16.jpg';

export const photos = [
  {
    original: g16,
  },
  {
    original: g1,
  },
  {
    original: g4,
  },
  {
    original: g5,
  },
  {
    original: g6,
  },
  {
    original: g8,
  },
  {
    original: g9,
  },
  {
    original: g13,
  },
  {
    original: g14,
  },
  {
    original: g15,
  },
];